<template>
    <div>
        <Header :instanceInfo="instanceInfo" />
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="box" style="margin-top:10px; border: none!important;">
                        <!--Main Start-->
                        <div class="container">
                            <div class="col-md-12">
                                <h3>Oops... something went wrong, your payment was rejected.</h3>
                                <p>Unfortunately your payment was rejected.<br>Please check if you've entered correct
                                    payment details.</p>
                                <p>
                                    <router-link to="/credits/subscription/direct-premium" class=":class">
                                        If you'd like to try it again, please click here.</router-link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer :instance-info="instanceInfo" :company-name="companyName" v-bind="$attrs" />
    </div>
</template>

<script>
import Header from '@/app/components/Header';
import Footer from '@/app/components/Footer';

export default {
    name: 'Failure',
    components: { Header, Footer },
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
        companyName: {
          type: String,
          required: false,
        },
    },
    setup() {
    },
};
</script>
